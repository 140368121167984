@mixin list-bare($jackhammer: false) {
    @if $jackhammer {
        margin-bottom: 0;
    }
    margin-left: 0;
    padding-left: 0;
    list-style: none;

    li {
        margin-bottom: 0;
    }

}
