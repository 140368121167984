:root {
	--color-primary: #{color(primary)};
	--color-base: #{color(base)};
	--color-highlight: #{color(highlight)};
	--color-highlight-hover: #{color(highlight-hover)};
	--color-secondary: #{color(secondary)};
	--color-disable: #{color(disable)};
	--color-overlay: #{color(overlay)};
	--color-white: #{color(tertiary)};
	--spacing-tiny: #{spacing(tiny)};
	--spacing-small: #{spacing(small)};
	--spacing-base: #{spacing(base)};
	--spacing-large: #{spacing(large)};
	--cookie-banner-width: 75%;
	--cookie-banner-width-large-screen: 60%;
	--cookie-banner-height: 86vh;
	--cookie-banner-overlay-background-color: var(--color-overlay);
	--cookie-banner-font-size: 16px;
	--cookie-banner-body-background: #F6F6F6;
	--cookie-banner-text-color: var(--color-secondary);
	--cookie-banner-text-color-alt: var(--color-primary);
	--cookie-banner-shadow: none;
	--cookie-banner-body-padding-vertical: 28px;
	--cookie-banner-body-padding-vertical-small-screen: var(--spacing-base);
	--cookie-banner-body-padding-horizontal: var(--spacing-large);
	--cookie-banner-body-padding-horizontal-small-screen: var(--spacing-base);
	--cookie-banner-body-spacing-bottom: var(--spacing-base);
	--cookie-banner-logo-width: 200px;
	--cookie-banner-logo-spacing-bottom: var(--spacing-base);
	--cookie-banner-headline-font-size: 19px;
	--cookie-banner-headline-font-weight: bold;
	--cookie-banner-headline-text-align: center;
	--cookie-banner-headline-line-height: 1;
	--cookie-banner-checkbox-item-spacing-bottom: var(--spacing-base);
	--cookie-banner-checkbox-size: 18px;
	--cookie-banner-checkbox-spacing-horizontal: var(--spacing-small);
	--cookie-banner-checkbox-border-radius: 4px;
	--cookie-banner-checkbox-color: var(--color-white);
	--cookie-banner-checkbox-background-color: transparent;
	--cookie-banner-checkbox-background-color-checked: var(--color-highlight);
	--cookie-banner-checkbox-background-color-disable: var(--color-disable);
	--cookie-banner-checkbox-border-width: 1px;
	--cookie-banner-checkbox-border-style: solid;
	--cookie-banner-checkbox-border-color: var(--color-primary);
	--cookie-banner-checkbox-border-color-checked: var(--color-highlight);
	--cookie-banner-checkbox-border-color-disabled: var(--color-disable);
	--cookie-banner-checkbox-icon-size: 14px;
	--cookie-banner-checkbox-icon-entity: "\2713";
	--cookie-banner-checkbox-icon-color: transparent;
	--cookie-banner-checkbox-icon-checked-color: var(--color-white);
	--cookie-banner-checkbox-icon-checked-color-disabled: var(--color-white);
	--cookie-banner-cookie-content-spacing-top: var(--spacing-tiny);
	--cookie-banner-cookie-category-description-spacing-bottom: var(--spacing-tiny);
	--cookie-banner-details-button-text-color: var(--color-primary);
	--cookie-banner-details-button-font-weight: bold;
	--cookie-banner-cookie-list-spacing-top: var(--spacing-base);
	--cookie-banner-cookie-item-spacing-horizontal: var(--spacing-tiny);
	--cookie-banner-cookie-item-spacing-bottom: var(--spacing-small);
	--cookie-banner-cookie-item-icon-spacing-horizontal: var(--spacing-small);
	--cookie-banner-cookie-item-icon-size: 10px;
	--cookie-banner-cookie-item-icon-stroke-width: 2px;
	--cookie-banner-cookie-item-icon-stroke-color: var(--color-primary);
	--cookie-banner-button-area-spacing-top: var(--spacing-base);
	--cookie-banner-button-spacing-vertical: var(--spacing-base);
	--cookie-banner-button-spacing-horizontal: var(--spacing-small);
	--cookie-banner-button-padding-vertical: var(--spacing-small);
	--cookie-banner-button-padding-horizontal: var(--spacing-base);
	--cookie-banner-button-border-radius: 0;
	--cookie-banner-button-border-width: 2px;
	--cookie-banner-button-border-style: solid;
	--cookie-banner-button-text-transform: uppercase;
	--cookie-banner-button-font-size: var(--cookie-banner-font-size);
	--cookie-banner-button-font-weight: bold;
	--cookie-banner-button-primary-text-color: var(--color-white);
	--cookie-banner-button-primary-background-color: var(--color-highlight);
	--cookie-banner-button-primary-border-color: var(--color-highlight);
	--cookie-banner-button-primary-hover-text-color: var(--color-white);
	--cookie-banner-button-primary-hover-background-color: var(--color-highlight-hover);
	--cookie-banner-button-primary-hover-border-color: var(--color-highlight-hover);
	--cookie-banner-button-secondary-text-color: var(--color-white);
	--cookie-banner-button-secondary-background-color: var(--color-highlight);
	--cookie-banner-button-secondary-border-color: var(--color-highlight);
	--cookie-banner-button-secondary-hover-text-color: var(--color-white);
	--cookie-banner-button-secondary-hover-background-color: var(--color-highlight-hover);
	--cookie-banner-button-secondary-hover-border-color: var(--color-highlight-hover);
	--cookie-banner-settings-button-text-color: var(--color-primary);
	--cookie-banner-settings-button-background-color: var(--cookie-banner-button-primary-background-color);
	--cookie-banner-settings-button-border-color: var(--cookie-banner-button-primary-border-color);
	--cookie-banner-settings-button-hover-text-color: var(--color-primary);
	--cookie-banner-settings-button-hover-background-color: var(--cookie-banner-button-primary-hover-background-color);
	--cookie-banner-settings-button-hover-border-color: var(--cookie-banner-button-primary-hover-border-color);
	--cookie-banner-link-text-color: var(--color-highlight);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-top: 0;
	margin-bottom: var(--cookie-banner-body-spacing-bottom);
}

h1:after {
	display: none;
}

#nsd-cookie-consent {
	display: none;
}

.c-cookie-banner {
	color: var(--cookie-banner-text-color);
	font-size: var(--cookie-banner-font-size);
	font-family: inherit;
	box-sizing: border-box;
}

* {

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}

}

.c-cookie-banner__overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: var(--cookie-banner-overlay-background-color);
	z-index: 9999;
}

.c-cookie-banner__body {
	position: fixed;
	padding-top: var(--cookie-banner-body-padding-vertical-small-screen);
	padding-bottom: var(--cookie-banner-body-padding-vertical-small-screen);
	box-shadow: var(--cookie-banner-shadow);
	background-color: var(--cookie-banner-body-background);
	height: 100%;
	width: 100%;
	overflow: auto;
	top: 0;
	left: 0;
	z-index: 10000;

	@include mq($from: m) {
		width: var(--cookie-banner-width);
		top: 50%;
		left: 50%;
		height: auto;
		max-height: var(--cookie-banner-height);
		padding-top: var(--cookie-banner-body-padding-vertical);
		padding-bottom: var(--cookie-banner-body-padding-vertical);
		transform: translate(-50%, -50%);
	}

	a {
		color: var(--cookie-banner-link-text-color);
		text-decoration: underline;
	}

	.c-cookie-banner__cookie-policy-link {
		color: var(--cookie-banner-link-text-color);
	}
}
@media (min-width: 73.75em){
	.c-cookie-banner__body {
		width: var(--cookie-banner-width-large-screen);
	}
}

.c-cookie-banner__logo {
	max-width: 100%;
	width: var(--cookie-banner-logo-width);
	margin-bottom: var(--cookie-banner-logo-spacing-bottom);
	margin-left: auto;
	margin-right: auto;

	svg {
		display: block;
		width: 100%;
	}
}

.c-cookie-banner__headline {
	margin-left: var(--cookie-banner-body-padding-horizontal-small-screen);
	margin-right: var(--cookie-banner-body-padding-horizontal-small-screen);
	font-size: var(--cookie-banner-headline-font-size);
	line-height: var(--cookie-banner-headline-line-height);
	text-align: var(--cookie-banner-headline-text-align);
	color: var(--cookie-banner-text-color-alt);

	@include mq($from: m) {
		margin-left: var(--cookie-banner-body-padding-horizontal);
		margin-right: var(--cookie-banner-body-padding-horizontal);
	}
}

.c-cookie-banner__text {
	margin-left: var(--cookie-banner-body-padding-horizontal-small-screen);
	margin-right: var(--cookie-banner-body-padding-horizontal-small-screen);
	max-width: 100%;

	@include mq($from: m) {
		margin-left: var(--cookie-banner-body-padding-horizontal);
		margin-right: var(--cookie-banner-body-padding-horizontal);
	}
}

.c-cookie-banner__footer-text {
	margin-left: var(--cookie-banner-body-padding-horizontal-small-screen);
	margin-right: var(--cookie-banner-body-padding-horizontal-small-screen);
	max-width: 100%;
	text-align: center;

	@include mq($from: m) {
		margin-left: var(--cookie-banner-body-padding-horizontal);
		margin-right: var(--cookie-banner-body-padding-horizontal);
	}
}

.c-cookie-banner__checkbox-list {
	@include list-bare($jackhammer: true);
	padding-left: var(--cookie-banner-body-padding-horizontal-small-screen);
	padding-right: var(--cookie-banner-body-padding-horizontal-small-screen);
	width: 100%;

	@include mq($from: m) {
		padding-left: var(--cookie-banner-body-padding-horizontal);
		padding-right: var(--cookie-banner-body-padding-horizontal);
	}
}

.c-cookie-banner__checkbox-item {
	width: 100%;

	&:not(:last-child) {
		margin-bottom: var(--cookie-banner-checkbox-item-spacing-bottom);
	}
}

.c-cookie-banner__checkbox-container {
	display: flex;
	align-items: center;

	&.c-cookie-banner__checkbox-container--disabled {
		pointer-events: none;
	}

}

.c-cookie-banner__checkbox {
	flex-basis: var(--cookie-banner-checkbox-size);
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: var(--cookie-banner-checkbox-size);
	height: var(--cookie-banner-checkbox-size);
	margin: 0;
	margin-bottom: 10px !important;
	border-radius: var(--cookie-banner-checkbox-border-radius);
	color: var(--cookie-banner-checkbox-color);
	border: var(--cookie-banner-checkbox-border-width) var(--cookie-banner-checkbox-border-style) var(--cookie-banner-checkbox-border-color);
	background-color: var(--cookie-banner-checkbox-background-color);
	-webkit-appearance: none;

	&:before {
		font-family: 'Zapf Dingsbats';
		content: var(--cookie-banner-checkbox-icon-entity);
		font-size: var(--cookie-banner-checkbox-icon-size);
		color: var(--cookie-banner-checkbox-icon-color);
	}

	&:checked {
		background-color: var(--cookie-banner-checkbox-background-color-checked);
		border-color: var(--cookie-banner-checkbox-border-color-checked);

		&:before {
			color: var(--cookie-banner-checkbox-icon-checked-color);
		}
	}

	&:focus {
		outline: none !important;
	}

	&:indeterminate {
		background-color: var(--cookie-banner-checkbox-background-color-checked);
		border-color: var(--cookie-banner-checkbox-border-color-checked);
	}

	&.c-cookie-banner__checkbox--disabled {
		border-color: var(--cookie-banner-checkbox-border-color-disabled);
		cursor: default;
		pointer-events: none;

		&:checked {
			border-color: var(--cookie-banner-checkbox-border-color-disabled);
			background-color: var(--cookie-banner-checkbox-background-color-disable);

			&:before {
				color: var(--cookie-banner-checkbox-icon-checked-color-disabled);
			}
		}
	}
}

.c-cookie-banner__checkbox-label {
	margin-left: var(--cookie-banner-checkbox-spacing-horizontal);
	color: var(--cookie-banner-text-color-alt);
	font-weight: bold;
}

.c-cookie-banner__cookie-content {
	display: none;
	margin-top: var(--cookie-banner-cookie-content-spacing-top);
	padding-left: calc(var(--cookie-banner-checkbox-size) + var(--cookie-banner-checkbox-spacing-horizontal));
}

.c-cookie-banner__category-description {
	margin-bottom: var(--cookie-banner-cookie-category-description-spacing-bottom);
	max-width: 100%;
}

.c-cookie-banner__details-button,
.c-cookie-banner__category-details-button,
.c-cookie-banner__privacy-details-button,
.c-cookie-banner__privacy-back-button {
	@include button-reset();
	cursor: pointer;
	color: var(--cookie-banner-details-button-text-color);
	font-weight: var(--cookie-banner-details-button-font-weight);

	.c-cookie-banner__button-area & {

		@include mq($from: m) {
			grid-column: span 2;
		}
	}
}

.c-cookie-banner__cookie-policy-link {
	@include button-reset();
	cursor: pointer;
	color: var(--cookie-banner-link-text-color);
	font-weight: var(--cookie-banner-details-button-font-weight);
	text-align: center;

	.c-cookie-banner__button-area & {

		@include mq($from: m) {
			grid-column: span 2;
		}
	}
}

.c-cookie-banner__cookie-list {
	margin-top: var(--cookie-banner-cookie-list-spacing-top);
	display: none;
}

.c-cookie-banner__cookie-item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: var(--cookie-banner-cookie-item-spacing-bottom);
}

.c-cookie-banner__cookie-item--heading {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	align-items: flex-start;
}

.c-cookie-banner__cookie-item--details {
	display: none;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	align-items: flex-start;
}

.c-cookie-banner__cookie-item--row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

.c-cookie-banner__cookie-name {
	flex-basis: 300px;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: var(--cookie-banner-cookie-item-spacing-horizontal);
	cursor: pointer;
}

.c-cookie-banner__cookie-provider-name,
.c-cookie-banner__cookie-storage-duration {
	flex-basis: 300px;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: var(--cookie-banner-cookie-item-spacing-horizontal);
}

.c-cookie-banner__cookie-icon {
	display: inline-block;
	width: var(--cookie-banner-cookie-item-icon-size);
	height: var(--cookie-banner-cookie-item-icon-size);
	border-width: var(--cookie-banner-cookie-item-icon-stroke-width) var(--cookie-banner-cookie-item-icon-stroke-width) 0 0;
	border-style: solid;
	border-color: var(--cookie-banner-cookie-item-icon-stroke-color);
	position: relative;
	left: -(var(--cookie-banner-cookie-item-icon-size) / 2);
	-webkit-transform: rotate(0.125turn);
	transform: rotate(0.125turn);
	margin-right: var(--cookie-banner-cookie-item-icon-spacing-horizontal);

	&.c-cookie-banner__cookie-icon--checked {
		transform: rotate(135deg);
		left: 0;
		top: -(var(--cookie-banner-cookie-item-icon-size) / 2);
		top: -2px;
	}
}

.c-cookie-banner__cookie-description {
	margin-right: auto;
}

.c-cookie-banner__description {
	margin-bottom: 0;
}

.c-cookie-banner__description-long {
	margin-top: var(--spacing-small);
	margin-bottom: 0;
}

.c-cookie-banner__cookie-checkbox {
	margin-left: var(--cookie-banner-cookie-item-spacing-horizontal);
}

.c-cookie-banner__button-area {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-row-gap: calc(var(--cookie-banner-button-spacing-vertical) / 2);
	grid-column-gap: var(--cookie-banner-button-spacing-horizontal);
	margin-top: var(--cookie-banner-button-area-spacing-top);
	margin-left: var(--cookie-banner-body-padding-horizontal-small-screen);
	margin-right: var(--cookie-banner-body-padding-horizontal-small-screen);

}

.c-cookie-banner__cookie-consent-area {
	grid-column: span 2;
	max-width: 100%;
	margin: 0;
	text-align: center;
}

.c-cookie-banner__button {
	border-radius: var(--cookie-banner-button-border-radius);
	border: var(--cookie-banner-button-border-width) var(--cookie-banner-button-border-style) var(--cookie-banner-button-primary-border-color);
	cursor: pointer;
	font-size: var(--cookie-banner-button-font-size);
	font-weight: var(--cookie-banner-button-font-weight);
	min-width: auto;
	padding: var(--cookie-banner-button-padding-vertical) var(--cookie-banner-button-padding-horizontal);
	text-transform: var(--cookie-banner-button-text-transform);
}

.c-cookie-banner__button--primary {
	color: var(--cookie-banner-button-primary-text-color);
	background-color: var(--cookie-banner-button-primary-background-color);
	border-color: var(--cookie-banner-button-primary-border-color);

	&:hover {
		color: var(--cookie-banner-button-primary-hover-text-color);
		background-color: var(--cookie-banner-button-primary-hover-background-color);
		border-color: var(--cookie-banner-button-primary-hover-border-color);
	}
}

.c-cookie-banner__button--secondary {
	color: var(--cookie-banner-button-secondary-text-color);
	background-color: var(--cookie-banner-button-secondary-background-color);
	border-color: var(--cookie-banner-button-secondary-border-color);

	&:hover {
		color: var(--cookie-banner-button-secondary-hover-text-color);
		background-color: var(--cookie-banner-button-secondary-hover-background-color);
		border-color: var(--cookie-banner-button-secondary-hover-border-color);
	}
}

.c-cookie-banner__button--back {
	color: var(--cookie-banner-button-secondary-text-color);
	background-color: var(--cookie-banner-button-secondary-background-color);
	border-color: var(--cookie-banner-button-secondary-border-color);
	margin-left: var(--cookie-banner-body-padding-horizontal);
	margin-bottom: var(--cookie-banner-body-padding-vertical);
}

.c-cookie-banner__button--flip-mobile {
	@include mq($until: s) {
		grid-row-start: 2;
	}
}

.c-cookie-banner__button--flip-mobile {
	@include mq($until: s) {
		grid-row-start: 2;
	}
}

.c-cookie-banner__back-button-wrapper {
	width: 100%;
	margin-bottom: var(--cookie-banner-body-spacing-bottom);
	padding-left: var(--cookie-banner-body-padding-horizontal-small-screen);
	padding-right: var(--cookie-banner-body-padding-horizontal-small-screen);

	@include mq($from: m) {
		padding-left: var(--cookie-banner-body-padding-horizontal);
		padding-right: var(--cookie-banner-body-padding-horizontal);
	}
}

.c-cookie-banner__footer {
	margin-top: var(--spacing-small);
	margin-bottom: var(--cookie-banner-body-padding-vertical);
}

.c-cookie-banner__footer-list {
	@include list-bare($jackhammer: true);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.c-cookie-banner__list-item {
	margin-left: var(--spacing-tiny);
	margin-right: var(--spacing-tiny);

	a {
		font-weight: bold;
		color: var(--cookie-banner-text-color-alt);
		cursor: pointer;
	}
}

.c-cookie-banner__settings-button {
	color: var(--cookie-banner-settings-button-text-color);
	background-color: var(--cookie-banner-settings-button-background-color);
	border-color: var(--cookie-banner-settings-button-border-color);

	&:hover {
		color: var(--cookie-banner-settings-button-hover-text-color);
		background-color: var(--cookie-banner-settings-button-hover-background-color);
		border-color: var(--cookie-banner-settings-button-hover-border-color);
	}
}
.c-chevron {
	border-color: var(--cookie-banner-button-primary-border-color);
}

/* IE10+ */
@media all and (-ms-high-contrast: none) {
	.c-cookie-banner__button-area {

		.c-cookie-banner__details-button,
		.c-cookie-banner__category-details-button,
		.c-cookie-banner__privacy-details-button,
		.c-cookie-banner__privacy-back-button {
			@include mq($from: m) {
				display: block;
				width: 100%;
				padding: var(--cookie-banner-button-spacing-vertical);
			}
		}

		.c-cookie-banner__button {
			width: calc(50% - calc(var(--cookie-banner-button-spacing-horizontal) / 2));

			&--primary {
				margin-right: calc(var(--cookie-banner-button-spacing-horizontal) / 2);
			}

			&--secondary {
				margin-left: calc(var(--cookie-banner-button-spacing-horizontal) / 2);
			}
		}

		.c-cookie-banner__cookie-policy-link {
			@include mq($from: m) {
				display: block;
				width: 100%;
				padding: var(--cookie-banner-button-spacing-vertical);
				text-align: center;
			}
		}
	}

	.c-cookie-banner__checkbox-label {
		margin-left: 0;
	}

	.c-cookie-banner__checkbox {
		position: absolute;
		left: -999em;

		+ label:before {
			content: "";
			display: inline-block;
			width: var(--cookie-banner-checkbox-size);
			height: var(--cookie-banner-checkbox-size);
			margin: 0 8px 0 0;
			vertical-align: text-top;
			border-radius: var(--cookie-banner-checkbox-border-radius);
			border: var(--cookie-banner-checkbox-border-width) var(--cookie-banner-checkbox-border-style) var(--cookie-banner-checkbox-border-color);
			text-align: center;
			font-family: 'Zapf Dingsbats';
			font-size: var(--cookie-banner-checkbox-icon-size);
		}

		&:checked,
		&:indeterminate {
			+ label:before {
				content: var(--cookie-banner-checkbox-icon-entity);
				color: var(--cookie-banner-checkbox-color);
				font-weight: bold;
				background-color: var(--cookie-banner-checkbox-background-color-checked);
				border-color: var(--cookie-banner-checkbox-border-color-checked);
			}
		}

		&.c-cookie-banner__checkbox--disabled {
			+ label:before {
				border-color: var(--cookie-banner-checkbox-border-color-disabled);
				background-color: var(--cookie-banner-checkbox-background-color-disable);
				cursor: default;
				pointer-events: none;
			}

			&:checked {
				+ label:before {
					font-family: 'Zapf Dingsbats';
					content: var(--cookie-banner-checkbox-icon-entity);
					font-size: var(--cookie-banner-checkbox-icon-size);
					cursor: default;
					pointer-events: none;
					color: var(--cookie-banner-checkbox-color);
				}
			}
		}
	}
}

/* EDGE 12-18 */
@supports (-ms-ime-align:auto) {
	.c-cookie-banner__checkbox {
		position: absolute;
		left: -999em;

		+ label:before {
			content: "";
			display: inline-block;
			width: var(--cookie-banner-checkbox-size);
			height: var(--cookie-banner-checkbox-size);
			margin: 0 8px 0 0;
			vertical-align: text-top;
			border-radius: var(--cookie-banner-checkbox-border-radius);
			border: var(--cookie-banner-checkbox-border-width) var(--cookie-banner-checkbox-border-style) var(--cookie-banner-checkbox-border-color);
			text-align: center;
			font-family: 'Zapf Dingsbats';
			font-size: var(--cookie-banner-checkbox-icon-size);
		}

		&:checked,
		&:indeterminate {
			+ label:before {
				content: var(--cookie-banner-checkbox-icon-entity);
				color: var(--cookie-banner-checkbox-color);
				font-weight: bold;
				background-color: var(--cookie-banner-checkbox-background-color-checked);
				border-color: var(--cookie-banner-checkbox-border-color-checked);
			}
		}

		&.c-cookie-banner__checkbox--disabled {
			+ label:before {
				border-color: var(--cookie-banner-checkbox-border-color-disabled);
				background-color: var(--cookie-banner-checkbox-background-color-disable);
				cursor: default;
				pointer-events: none;
			}

			&:checked {
				+ label:before {
					font-family: 'Zapf Dingsbats';
					content: var(--cookie-banner-checkbox-icon-entity);
					font-size: var(--cookie-banner-checkbox-icon-size);
					color: var(--cookie-banner-checkbox-color);
					cursor: default;
					pointer-events: none;
				}
			}
		}
	}
}

.c-cookie-banner__details-container {
	text-align: right;
	margin-top: var(--cookie-banner-button-area-spacing-top);
	margin-left: var(--cookie-banner-body-padding-horizontal-small-screen);
	margin-right: var(--cookie-banner-body-padding-horizontal-small-screen);

	@include mq($from: m) {
		margin-left: var(--cookie-banner-body-padding-horizontal);
		margin-right: var(--cookie-banner-body-padding-horizontal);
	}
}

.c-cookie-banner__arrow-up {
	border: solid var(--cookie-banner-button-secondary-background-color);
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(-135deg);
}

.c-cookie-banner__arrow-down {
	margin-bottom: 5px;
	border: solid var(--cookie-banner-button-secondary-background-color);
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(45deg);
}


.c-cookie-banner__settings-button {
	padding: 12px;
	border-radius: 4px;
}
